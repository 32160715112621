import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const {
    pageData: {
      linkroll
    }
  } = data

  return (
  <Layout title="Recommended Websites">
    <SEO title="Recommended Websites" />
    <section>
      <div className="container">
        <div className="columns is-8 is-variable">
          <div className="column news">
            <h2 className="title">These are some of the websites the church in Cypress recommends:</h2>
            <div className="post-content clear-fix">
              <div className="post-entry">
                  {linkroll.map(site => (
                    <div key={linkroll.href} className="link">
                      <h4 className="subtitle">
                        <a href={site.href} target="_blank" rel="noopener noreferrer">
                          {site.title}
                        </a>
                      </h4>
                      <p>{site.subtitle}</p>
                    </div>
                  ))}
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <style jsx="jsx">{`
    h4.subtitle:not(:last-child) {
      margin-bottom: 0;
    }
    .link {
      margin-bottom: 2em;
    }
  `}</style>
</Layout>
)}

export default IndexPage

export const query = graphql`
  query LinksQuery {
    pageData {
      linkroll {
        href
        subtitle
        title
      }
    }
  }
`